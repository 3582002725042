@use 'styles/theme/theme' as theme;

.planCard {
  width: 30%;
  max-width: 215px;
  height: 470px;

  border-radius: 5px;
  border-radius: 6px 6px 0px 0px;
  position: relative;
  border: 1px solid theme.$borderGrey;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    width: 100%;
    height: auto;
    max-width: 100%;
  }
  &__priceWrapper {
    display: flex;
  }
  &__header {
    border-radius: 6px 6px 0px 0px;
    background: linear-gradient(180deg, theme.$whiteT0 0%, theme.$whiteT50 100%);
    height: 115px;
    padding-top: 10px;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    &_expanded {
    }
    @media screen and (max-width: 600px) {
      border-radius: 6px;
    }
  }
  &__mobileExpandIcon {
    position: absolute;
    right: 20px;
    transform: rotate(90deg);
    svg {
      fill: theme.$blue;
    }
  }
  &__description {
    max-width: 70px;
  }
  &__description_fullWidth {
    width: 100%;
    max-width: 100%;
    margin-top: 20px;
  }
  &__headerTop {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 6px;
  }
  &__content {
    flex-shrink: 0;
    margin-top: 0;
  }
  &__ctaBadge {
    height: 26px;
    background-color: theme.$red-light-01;
    position: absolute;
    bottom: -5px;
    padding: 9px 18px;
    left: 0px;
    display: flex;
    width: 100%;
    &_ctaText {
      font-size: 11px;
      color: theme.$red;
    }
  }
  &__cta {
    font-size: 10px;
  }
  &__planInfo {
    display: flex;
    align-items: center;
    gap: 8px;
    @media screen and (max-width: 600px) {
      justify-content: space-between;
    }
  }
  &__features {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    padding-left: 18px;
    margin-top: 24px;
    gap: 14px;
    padding-right: 8px;
    &_list {
      display: flex;
      gap: 8px;
      svg {
        fill: theme.$orange3;
        min-width: 16px;
      }
    }
  }
  &__footer {
    display: flex;
    bottom: 37px;
    left: 30px;
    flex-shrink: 0;
    margin-top: auto;
    padding-left: 30px;
    padding-right: 18px;
    padding-bottom: 28px;
    gap: 8px;
    justify-content: space-between;
    @media screen and (max-width: 600px) {
      background-color: theme.$blue;
      position: static;
      bottom: 0;
      left: 0;
      justify-content: center;
      padding: 12px;
      width: 100%;
      border-radius: 0px 0px 6px 6px;
      button {
        color: theme.$white;
        svg {
          fill: theme.$white !important;
        }
      }
    }
  }
}
.subActionCta {
  margin: 18px;
}

.badge {
  display: flex;
  padding: 3px 7px;
  align-items: flex-start;
  border-radius: 28px;
  background-color: theme.$orange3;
  justify-content: center;
  &_text {
    font-size: 10px;
    color: theme.$white;
  }
}
