@use 'styles/theme/theme';

.paywallContent {
  @media screen and (max-width: 600px) {
    margin-bottom: 32px;
  }
  &__title {
    margin-bottom: 24px;
    @media screen and (max-width: 600px) {
      margin-bottom: 12px;
    }
  }

  &__desc {
    margin-bottom: 24px;
    color: theme.$secondaryGrey;
    width: 90%;
    @media screen and (max-width: 600px) {
      width: 100%;
      margin-bottom: 16px;
    }
    p {
      margin-bottom: 12px;
    }
  }

  &__works {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    gap: 50px;
    @media screen and (max-width: 1024px) {
      gap: 20px;
      margin-bottom: 16px;
    }
    flex-wrap: wrap;

    &_item {
      position: relative;
      color: theme.$secondaryGrey;
      &:not(:last-child)::after {
        content: '|';
        position: absolute;
        right: -25px;
        top: 50%;
        transform: translateY(-50%);
        @media screen and (max-width: 1024px) {
          right: -10px;
        }
      }
    }
  }

  &__stats {
    margin-bottom: 24px;
    @media screen and (max-width: 600px) {
      margin-bottom: 0;
    }
    &__inner {
      display: flex;
      flex-wrap: wrap;
      gap: 21px;
      @media screen and (max-width: 600px) {
        gap: 8px;
        justify-content: center;
      }
      &_statItem {
        margin-right: 10px;
        color: theme.$secondaryGrey;
      }
      .paywallContent__stats__inner_statItem {
        &:not(:last-child)::after {
          content: '•';
          position: absolute;
          color: theme.$tertiaryGrey;
          margin-left: 12px;
          margin-right: 12px;
          font-size: 20px;
          line-height: 20px;
          @media screen and (max-width: 600px) {
            margin-left: 6px;
            margin-right: 6px;
            font-size: 20px;
            line-height: 17px;
          }
        }
      }

      &_statInfo {
        margin-left: 5px;
        &_num {
          color: theme.$primaryGrey;
        }
      }
    }
  }
}
