@use 'styles/theme/theme' as theme;

.loggedInPaywall {
  max-width: 1280px;
  position: relative;
  margin: 0 auto;
  &__header {
    &_closeBtn {
      position: absolute;
      right: 80px;
      top: 20px;
      @media screen and (max-width: 600px) {
        right: 20px;
        top: 10px;
      }
    }
  }

  &__content {
    padding: 40px 0;
    display: flex;
    gap: 30px;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &__left {
    width: 70%;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
    @media screen and (max-width: 1290px) {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  &__leftHeader {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 17px;
    &__titleWrap {
      display: flex;
      flex-direction: column;
    }
    @media screen and (max-width: 600px) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
      text-align: center;
      width: 300px;
      margin: 0 auto;
      margin-bottom: 17px;
    }
  }

  &__right {
    width: 30%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    @media screen and (max-width: 600px) {
      width: 100%;
      padding: 0;
    }
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
  .toggleSwitch {
    height: 36px;
    padding: 3px;
  }

  .mainLogoSection {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    align-items: center;
    text-align: center;
    &__logoImage {
      &_sinceTitle {
        margin-left: 16px;
      }
    }
  }
}

.testimonials {
  display: flex;
  &__testimonialsSection {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 10px;
    .testimonialsContainer {
      div,
      p,
      span {
        font-size: 12px !important;
      }
    }
  }
  &__trustedSection {
    text-align: center;
    margin-top: 23px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.listWorks {
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  position: relative;
  li {
    list-style: none;
    margin-right: 3px;
    position: relative;
    padding-left: 6px;

    &:not(:first-child)::before {
      content: '•';
      position: absolute;
      left: 0;
      top: 50%;
      bottom: -5px;
      transform: translateY(-50%);
      font-size: 12px;
      color: theme.$secondaryGrey;
    }
  }
}

.plans {
  display: flex;
  gap: 10px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
  @media screen and (min-width: 601px) and (max-width: 800px) {
    flex-wrap: wrap;
  }
}

.comparePlanModal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff5f0;
  &__main {
    width: 100%;
  }
  &__header {
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 5px;
    justify-content: end;
    padding-right: 20px;
  }
}

.comparePlanModalContainer {
  width: 1024px !important;
  max-height: 500px !important;
  @media screen and (max-width: 600px) {
    width: 100% !important;
    height: auto !important;
  }
}
.modalRoot {
  padding: 100px !important;
  overflow-y: unset !important;
}

.modalContent {
  padding: 22px !important;
  overflow-y: unset !important;
  max-height: 500px !important;
  overflow: scroll;
}

.paywallContent__stats {
  margin-bottom: 10px;
}

.separator {
  width: 90px;
  height: 1px;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: theme.$borderGrey;;
}
