@use 'styles/theme/theme' as *;

.badge {
  display: flex;
  padding: 3px 7px;
  align-items: flex-start;
  border-radius: 32px;
  background-color: $orange3;
  justify-content: center;
  margin-top: 10px;
  &_text {
    font-size: 10px;
    color: $white;
  }
}

.pricingTable {
  border-radius: 8px;
  border: 1px solid $redLight;
  border-left: 0;
  border-bottom: 0px;
  margin: 32px 17px 0 35px;
}

.tableHeader {
  &__cellBorder {
    &:not(:last-child) {
      border-left: 1px solid $redLight;
      border-right: 1px solid $redLight;
    }
  }
  &__plan {
    width: 219px;
    border-bottom: 1px solid $redLight;
    padding: 15px;
    &_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 6px;
      .continueBtn {
        max-width: 100px;
      }
    }
  }

  &__feature {
    width: 368px;
    padding: 15px;
    border-left: 1px solid $redLight;
    border-bottom: 1px solid $redLight;
    & > span {
      text-align: left;
      width: 100%;
      display: flex;
    }
  }
}

.bodyFeatureCell {
  padding: 15px;
  text-align: center;
  border-bottom: 1px solid $redLight;
  padding: 15px;
  text-align: left;
  border-left: 1px solid $redLight;
  border-right: 0px;
  @media screen and (max-width: 1024px) {
    word-wrap: break-word;
    white-space: normal;
    max-width: 130px;
  }
}

.bodyPlanCell {
  padding: 15px;
  text-align: center;
  border-bottom: 1px solid $redLight;
  @media screen and (max-width: 1024px) {
    word-wrap: break-word;
    white-space: normal;
    max-width: 100px;
  }
}

tr .bodyPlanCell:nth-of-type(2) {
  border-left: 1px solid $redLight;
}

tr .bodyPlanCell:not(:last-of-type) {
  border-right: 1px solid $redLight;
  border-left: 1px solid $redLight;
}

tr .bodyPlanCell:not(:last-of-type) {
  border-right: 1px solid $redLight;
}

.tableBody {
  & > :last-child {
    & > :first-child {
      border-bottom-left-radius: 8px;
    }
    & > :last-child {
      border-bottom-right-radius: 8px;
    }
  }
}

.tableBodyWithFooter {
  & > :nth-last-child(2) {
    & > :first-child {
      border-bottom-left-radius: 8px;
    }
  }
  & > :last-child {
    & > :first-child {
      border-right: 0;
    }
    & > :nth-child(2) {
      border-left: 1px solid $redLight;
      border-bottom-left-radius: 8px;
    }
  }
}

@media screen and (max-width: 600px) {
  .pricingTable {
    order: 3;
    margin: 24px 16px 0 16px;
  }

  .tableHeader {
    &__plan {
      width: 80px;
      &_container {
        width: 100%;
      }
    }
    &__feature {
      width: 182px;
    }
  }

  .continueBtn {
    display: none;
  }
}
