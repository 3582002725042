@use 'styles/theme/theme' as theme;

.modalContainer {
  width: 463px !important;
  position: static !important;
  max-height: 620px;
  @media screen and (max-width: 600px) {
    width: 100% !important;
  }
  border-radius: 16px;
}
.closeIcon {
  top: 9px !important;
}

.modalRoot {
  padding: 50px 0;
  z-index: 99999;
}

.disabled {
  pointer-events: none;
}

.drawerRoot {
  z-index: 99999;
}

.content {
  border-bottom: 1px solid theme.$borderGrey;
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: 20px;
}

.modalFooter {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
  border-radius: 4px;
  border: 1px solid theme.$whiteSmoke;

  &__customerLogo {
    gap: 8px;
    display: flex;
    margin-top: 12px;
    text-align: center;
    flex-direction: column;
    width: 100%;
  }
}

.trustedByStrip {
  border-bottom: 1px solid theme.$whiteSmoke;
  padding: 12px 14px 12px 0px;
}

.testimonialContainer {
  > div {
    gap: 0;
    > div {
      padding: 16px 6px;
      border-bottom: 1px solid theme.$whiteSmoke;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.castingToolPaywall {
  display: flex;
  flex-direction: column;
  padding: 24px;
  .title {
    margin-bottom: 28px;
    text-align: center;
  }
  .actions {
    display: flex;
    flex-direction: column;
    gap: 22px;
  }
}

.profileCardList {
  display: flex;
  padding: 12px 14px;
  border-radius: 6px;
  border: 1px solid theme.$borderGrey;
  background: theme.$white;
  gap: 8px;
  justify-content: space-between;
  &__arrowRight {
    margin: auto 0;
    svg {
      fill: theme.$tertiaryGrey;
    }
  }
  cursor: pointer;
}

.profileSelection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  @media screen and (max-width: 600px) {
    padding: 12px;
  }
  .title {
    text-align: center;
  }
}

.castingToolPaywallContainer {
  @media screen and (min-width: 601px) {
    position: fixed;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -80%);
    width: 463px;
    padding: 24px;
    background: white;
    z-index: 9;
    border-radius: 8px;
    box-shadow: theme.$boxShadow3;
    max-height: 520px;
    overflow: auto;
  }
}

.closeBtn {
  position: absolute;
  right: 10px;
  top: 10px;
}
