@use 'styles/theme/theme';

.cohortTypeSelector {
  &__header {
    gap: 20px;
    display: flex;
    align-items: center;
    &_button {
      margin-left: 18px;
    }
  }
  &__buttonsWrap {
    margin-top: 18px;
    button {
      margin-bottom: 10px;
      width: 100%;
      height: 31px;
      font-weight: 400;
      font-size: 12px;
      color: theme.$primaryGrey;
      white-space: normal;
      line-height: 1.1;
      padding: 2px;
    }
  }
}
.titleCohort {
  white-space: nowrap;
}

.subscriptionCastingTool {
  &__header {
    display: flex;
    flex-direction: column;
    &__demoLink {
      margin-bottom: 16px;
      text-align: right;
    }
    &__actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
    }
  }

  &__pricing {
    margin-top: 18px;
    margin-bottom: 16px;
    display: flex;
    &_left {
      width: 70%;
    }
    &_right {
      width: 30%;
      text-align: center;
    }
  }
  &__content {
    margin-bottom: 18px;
  }

  &__featureTable {
    &__row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0;
      border-bottom: 1px solid theme.$borderGrey;
      &:nth-last-child(1) {
        border: none;
        padding-bottom: 0;
      }
      &:nth-child(1) {
        padding-top: 0;
      }
      &_title {
        width: 70%;
      }
      &_data {
        width: 30%;
        text-align: center;
        &_icon {
          height: 16px;
          width: 16px;
          fill: theme.$green;
        }
      }
    }
  }
  &_button {
    width: 100%;
  }
}

.separatorLine {
  height: 1px;
  background: theme.$borderGrey;
  width: 100%;
}
